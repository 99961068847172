<template>
  <base-section id="k-d-a-career-details" space="48" padless-bottom>
    <v-container class="px-7 px-md-0">
      <v-row
        v-if="m_objSelectedVacancy && m_objSelectedVacancy.strID"
        align="center"
        justify="space-between"
      >
        <v-col cols="12">
          <k-d-a-career-card
            :str-vacancy-title="m_objSelectedVacancy.strTitle"
            :str-vacancy-description="m_objSelectedVacancy.strDescription"
            :t-date-posted="m_objSelectedVacancy.tDatePosted"
            :t-date-closing="m_objSelectedVacancy.tDateClosing"
            :str-label="m_objSelectedVacancy.strLabel"
            :hex-label-color="m_objSelectedVacancy.hexLabelColor"
            b-info-only
          />
        </v-col>

        <v-col
          v-for="(detail, i) in m_objSelectedVacancy.arrDetails"
          :key="'career-detail-container-' + i"
          cols="12"
        >
          <k-d-a-career-details-card
            :str-details-title="detail.strHeading"
            :str-details-text="detail.strText"
            :arr-details-points="detail.arrPoints"
          />
        </v-col>

        <v-col v-if="!g_bLowerBr" cols="12" class="mt-4">
          <div style="border: 1px solid #696969; width: 100%"></div>
        </v-col>
      </v-row>

      <p v-else class="kda-ts-16pt mb-4 mb-md-10">
        {{ m_strVacancyNotFound }}
      </p>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import KDASelectedVacancy from './extension/KDASelectedVacancy';

export default {
  name: 'KDACareerDetails',

  components: {
    KDACareerCard: () => import('@/components/KDACareerCard'),
    KDACareerDetailsCard: () => import('@/components/KDACareerDetailsCard')
  },

  extends: KDASelectedVacancy,

  mixins: [BaseBreakpoint]
};
</script>
